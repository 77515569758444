<template>
  <v-container fluid>
    <v-row>
      <v-col cols="12" class="d-flex align-center justify-start">
        <app-bar-route />
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
export default {
  name: "AppBarSimple",
  components: { AppBarRoute: () => import("../AppBarComponents/AppBarRoute") },
};
</script>
